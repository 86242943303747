import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/next-portfolio/components/divisor/index.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/next-portfolio/components/sections/download/index.tsx");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/.pnpm/next@14.2.15_@babel+core@7.25.8_@playwright+test@1.48.0_babel-plugin-macros@3.1.0_react-dom@1_ld3ga3dhnjf5onjolbvnq7nldm/node_modules/next/dist/client/link.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/shared-styles/src/clock/styles.module.css");
;
import(/* webpackMode: "eager" */ "/vercel/path0/shared-styles/src/divisibles/styles.module.css");
;
import(/* webpackMode: "eager" */ "/vercel/path0/shared-styles/src/hamburger/styles.module.css");
;
import(/* webpackMode: "eager" */ "/vercel/path0/shared-styles/src/layout/styles.module.css");
;
import(/* webpackMode: "eager" */ "/vercel/path0/shared-styles/src/nav/styles.module.css");
;
import(/* webpackMode: "eager" */ "/vercel/path0/shared-styles/src/section/styles.module.css");
;
import(/* webpackMode: "eager" */ "/vercel/path0/shared-styles/src/sections/education/styles.module.css");
;
import(/* webpackMode: "eager" */ "/vercel/path0/shared-styles/src/sections/experience/styles.module.css");
;
import(/* webpackMode: "eager" */ "/vercel/path0/shared-styles/src/sections/projects/styles.module.css");
;
import(/* webpackMode: "eager" */ "/vercel/path0/shared-styles/src/sections/skills/styles.module.css");
;
import(/* webpackMode: "eager" */ "/vercel/path0/shared-styles/src/styled-go-back/styles.module.css");
;
import(/* webpackMode: "eager" */ "/vercel/path0/shared-styles/src/three-dots/styles.module.css");
;
import(/* webpackMode: "eager" */ "/vercel/path0/shared-styles/src/tooltip/styles.module.css");
;
import(/* webpackMode: "eager" */ "/vercel/path0/shared-styles/src/view-source/styles.module.css");
